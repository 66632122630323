:root {
  --blue: rgba(49,116,155,1);
  --red: rgba(240,62,69,1);
  --brown-trans: rgba(136, 85, 15, 0.4);
  --semi-trans: rgba(0,0,0,0.5);
  --semi-trans-lighter: rgba(0,0,0,0.3);
  --light-border: rgba(255, 255, 255, 0.1);
  --menubg : rgba(0,0,0,0.8);
}

html,body {font: 300 20px/1.5em 'Open Sans',sans-serif;color:#444;margin:0;padding:0;background-color: white;}
h1,h2,h3,h4,h5,h6 {font-family:'Martel Sans',sans-serif;font-weight:700;line-height:1.5em;margin:1.5rem 0}
p,form,figure {margin:1.5rem 0}

body > div#root > section[role=navigation] {position: fixed;top:0rem;display:flex;align-items:center;width:100%;
  justify-content: space-evenly;background-color: var(--semi-trans);z-index:1;
  box-shadow: 0px 1px 0.1rem var(--semi-trans-lighter);
}
body > div#root > section[role=navigation] nav {max-width:1000px;width:100%}
body > div#root > section[role=navigation] .favicon {height:1.5rem}

body > div#root > header {background:url(/public/images/hero.jpg) top center;background-size: cover;}

body > div#root > header.intro {position:relative;}
body > div#root > header.intro .logo {position: absolute;transform: translate(-50%,-50%);top:50%;left:50%;}
body > div#root > header.intro .logo.shadow {top:calc(50% + 2px);left: calc(50% + 0px);}
body > div#root > header.intro video {width:100%;display:block;min-height:55vw;}
body > div#root > header.intro:after {content:'';display:block;position:absolute;top:0;left:0;width:100%;height:100%;background-color: var(--brown-trans);}


@keyframes scrolldown {
  0%   {bottom: 4rem}
  30%  {bottom: 5rem}
  40%  {bottom: 4rem}
  100% {bottom: 4rem}
}

h1::before {background-color: var(--red);width:4px;content:'';margin-right: 0.5rem;align-items: stretch;}
h1 {display: flex;flex-direction: row;}

h4 {margin-bottom:0}
h4+p {margin-top:0}
 
main {max-width:1000px;padding:2ch;margin:0 auto;display:block}
main a {color: var(--red);font-weight: 400;text-decoration: none;transition: color 0.3s;}
main a:hover {color: var(--blue)}
main nav {display:flex;flex-wrap:wrap}
main nav a:not(:first-child)::before {content:'|';color:#ddd;text-align: center;padding:0 0.25rem}
main img {max-width:100%}

.led {text-transform: uppercase;}
.led:before {content:url(/public/images/arrow.png);font-family: 'FontAwesome';display:inline-block;padding:5px 3px;margin:0 5px 0 0;color:#fff;vertical-align: baseline;}
.led.reflex:before {content:url(/public/images/led_reflex.png);} 
.led.reflex {color: #ba8c2b}
.led.blue {color:#0e71b8}
.led.green {color:#0b8e36}
.led.red {color:#e30613}
.led.black {color:#3c3c3b}
.led.orange {color:#f39200}

.led.blue:before {background-color:#0e71b8}
.led.green:before {background-color:#0b8e36}
.led.red:before {background-color:#e30613}
.led.black:before {background-color:#3c3c3b}
.led.orange:before {background-color:#f39200}

em {font-style: normal;font-weight: 400;color: var(--blue);border-bottom: 2px solid var(--blue);}
figure {font-size:14px;color: #999;;font-weight: 400;line-height:1.5em;text-align: center;}

footer {background:#eee;color:#444;display:flex;align-items: center;justify-content: center;flex-wrap:wrap;padding:1.5rem }
footer a {color:#888;text-decoration: none;padding:0 1rem;white-space: nowrap;}

.embed-container {position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; }
.embed-container iframe, .embed-container object, .embed-container embed {position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

.error {background-color: rgba(240,62,69,0.15);padding: 0.5rem 1rem;}

form {width:100%}
label {display:block;font-size: 0.85rem;font-weight: 400;}
select,textarea,input {display:block;width:100%;padding:0.25rem 0.5rem;border:1px solid var(--semi-trans-lighter);border-radius:0.25rem;
  margin-bottom:1rem;box-sizing: border-box;font: inherit;}
input[type=submit] {background-color: var(--blue);color:#fff;transition: filter 0.1s;}
input[type=submit]:hover {filter: brightness(120%);}
  .meta {font-size:0.8rem;}


@media screen and (min-width:1024px)
{
  .cols {display:flex;gap:1rem;flex-direction: row;}
  section[role=navigation] nav ul {margin:0;display:flex;padding:0;list-style: none;text-shadow: 1px 1px 1px var(--semi-trans-lighter);}
  section[role=navigation] nav > ul > li {display:inline-flex;position: relative;}
  section[role=navigation] nav ul li:hover {background-color: var(--blue);}

  section[role=navigation] nav ul li a {display:flex;padding:0.25em 0.5em;text-decoration: none;font-weight:400;color:#fff;}
  section[role=navigation] nav ul li a.active {text-decoration: underline;}
  
  section[role=navigation] nav ul li a:hover {background-color: var(--light-border);}
  
  section[role=navigation] nav > ul > li > ul {position: absolute;display:block;top:2em;}
  section[role=navigation] nav ul li ul li {display:block;opacity: 0;transition: opacity 0.3s;background:var(--blue);}

  section[role=navigation] nav ul li:hover ul li {display:block;opacity: 1;}

  #menuToggle,#menuIcon {display:none}
  body > div#root > header.intro:before {content:'\f13a';font-family:'FontAwesome';position: absolute;bottom:4rem;color:#fff;font-size:2rem;
    text-shadow: 1px 1px 2px var(--semi-trans);animation-name: scrolldown;z-index:10;
    animation-duration: 1.5s;animation-iteration-count: infinite;opacity:0.8;left:calc(50vw - 1rem)}
    
}

@media screen and (max-width:1023px)
{
    #menuToggle {display:block;opacity: 1;position:fixed;top:1rem;right:1rem;z-index:10; -webkit-touch-callout: none;width:40px;height:40px;opacity:0;}
    #menuIcon {
      display:block;position:fixed;top:1rem;right:1rem;font-size:2rem;z-index:1;color: white;transition: transform 0.5s;
      text-shadow: 1px 1px 1px black;
    }
    
    #menuToggle ~ ul {position:fixed;top:0;right:0;transform: translate(100%,0%);padding-top:3rem;background:var(--menubg);transition: transform 0.5s;
      height:100vh;box-sizing: border-box;}
    #menuToggle:checked ~ #menuIcon {transform: rotate(0deg);}
    #menuToggle:checked ~ ul {transform: none;}

    section[role=navigation] nav {text-align:left;z-index:100}
    section[role=navigation] nav ul li a:hover,section[role=navigation] nav ul li a.active {background: var(--blue);color: #fff;}
    section[role=navigation] nav ul {list-style: outside none none;margin:0;padding:0}
    section[role=navigation] nav ul li ul li a {padding-left: 2rem;}

    section[role=navigation] nav ul li a {
      border-bottom: 1px solid var(--light-border);
      color: #fff;display: block;max-width: 90%;min-width: 30vw;padding: 0.25rem 1rem;text-decoration: none;
      
    }
    body > div#root > section[role=navigation]  {display:block;text-align:center}
    .hideOnMobile {display:none}

}